.ProductPage-details {
  margin-top: 2em;
}

.ProductPage-details h2 {
  font-family: Lora;
  font-size: 26px;
  margin: 0.25em 0 0.5em;
}

.ProductPage-details .designer {
  margin-bottom: 2em;
  text-transform: uppercase;
  color: #4e4e51;
}

.ProductPage .product-image {
  background-color: #f7f7f9;
  height: 22em;
  text-align: center;
  vertical-align: middle;
}

.ProductPage .product-image img {
  height: 15em;
}

.ProductPage .has-discount-false,
.ProductPage .has-discount-true {
  margin-bottom: 0.25em;
}

.ProductPage .has-discount-true .price {
  color: DimGray;
}

.ProductPage .has-discount-true .price {
  text-decoration: line-through;
}

.ProductPage .has-discount-false .discount,
.ProductPage .has-discount-true .price span {
  display: none;
}

.ProductPage .discount {
  font-size: 110%;
  font-weight: 900;
  padding: 0 0.5em;
  color: SandyBrown;
}

.ProductPage .buttons {
  margin: 1.5em 0;
}

.ProductPage .buttons a {
  border-radius: 4px;
  margin: 0 0 0.5em 0;
  display: block;
  text-align: center;
  padding: 0.75em 1.5em;
  text-transform: capitalize;
  width: 200px;
}

@media only screen and (min-width: 768px) {
  article.ProductPage {
    max-width: 1024px;
    width: 75%;
  }

  .ProductPage-details {
    display: flex;
    flex-direction: row;
  }

  .ProductPage .product-image {
    margin-right: 3em;
    width: 15em;
  }
}
