header form {
  position: absolute;
  top: 5em;
  left: 0;
  right: 0;
  padding: 1em;
  background-color: rgba(255, 255, 255, .5);
}

header form ul,
header form input[type='search'] {
  width: 100%;
  max-width: 600px;
}

header form input[type='search'] {
  -webkit-appearance: textfield;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.2797 20.0891L16.0203 14.8297C15.9828 14.7922 15.9781 14.7313 16.0109 14.6891C16.5195 14.0586 16.9344 13.3648 17.2531 12.6148C17.7078 11.5367 17.9375 10.3953 17.9375 9.21875C17.9375 8.04219 17.7078 6.90078 17.2531 5.825C16.8125 4.78672 16.1844 3.85391 15.3828 3.05469C14.5812 2.25313 13.6508 1.625 12.6125 1.18672C11.5367 0.729688 10.3953 0.5 9.21875 0.5C8.04219 0.5 6.90078 0.729687 5.825 1.18437C4.78672 1.625 3.85391 2.25313 3.05469 3.05469C2.25312 3.85625 1.625 4.78672 1.18672 5.825C0.729688 6.90078 0.5 8.04219 0.5 9.21875C0.5 10.3953 0.729687 11.5367 1.18437 12.6125C1.625 13.6508 2.25312 14.5836 3.05469 15.3828C3.85625 16.1844 4.78672 16.8125 5.825 17.2508C6.90078 17.7078 8.04219 17.9375 9.21875 17.9375C10.3953 17.9375 11.5367 17.7078 12.6125 17.2531C13.3625 16.9367 14.0562 16.5195 14.6867 16.0109C14.7289 15.9758 14.7898 15.9805 14.8273 16.0203L20.0867 21.2797C20.3797 21.5727 20.8531 21.5727 21.1461 21.2797L21.2773 21.1484C21.5727 20.8555 21.5727 20.382 21.2797 20.0891ZM9.21875 16.25C5.34219 16.25 2.1875 13.0953 2.1875 9.21875C2.1875 5.34219 5.34219 2.1875 9.21875 2.1875C13.0953 2.1875 16.25 5.34219 16.25 9.21875C16.25 13.0953 13.0953 16.25 9.21875 16.25Z' fill='black'/%3E%3C/svg%3E");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 10px;
  border: solid 1px black;
  font-size: 1em;
  text-indent: 30px;
  border-radius: 3px;
  font-size: 1em;
  outline: none;
  padding: 0.75em;
}

header form ul {
  display: none;
}

@media only screen and (min-width: 768px) {
  header form {
    position: static;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    margin: 10px 0 0;
    padding: 0;
  }

  header form ul {
    margin: 1em 0 0.1em;
    display: flex;
    justify-content: space-between;
  }

  header form ul li {
    list-style: none;
    display: inline-block;
  }

  header form li a {
    margin: 1em 0 0;
    padding: 0;
    text-transform: uppercase;
    color: #000;
    border-radius: 4px;
    font-size: smaller;
    padding: 1em;
    text-decoration: none;
    white-space: nowrap;
  }

  header form li a:hover {
    color: #4cadcd;
  }

  header form li.my-list {
    border-left: solid 1px #000;
    padding-left: 1em;
  }

  header form li.my-list a {
    background: no-repeat left center
      url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 12C6.86458 12 6.75 11.9531 6.65625 11.8594L1.78125 7.15625C1.72917 7.11458 1.65755 7.04688 1.56641 6.95313C1.47526 6.85938 1.33073 6.6888 1.13281 6.44141C0.934896 6.19401 0.757812 5.94011 0.601562 5.67969C0.445312 5.41927 0.30599 5.10417 0.183594 4.73438C0.0611979 4.36458 -1.82292e-08 4.00521 0 3.65625C0 2.51042 0.330729 1.61458 0.992188 0.96875C1.65365 0.322917 2.56771 0 3.73438 0C4.05729 0 4.38672 0.0559896 4.72266 0.167969C5.05859 0.279948 5.37109 0.43099 5.66016 0.621094C5.94922 0.811198 6.19792 0.989583 6.40625 1.15625C6.61458 1.32292 6.8125 1.5 7 1.6875C7.1875 1.5 7.38542 1.32292 7.59375 1.15625C7.80208 0.989583 8.05078 0.811198 8.33984 0.621094C8.62891 0.43099 8.94141 0.279948 9.27734 0.167969C9.61328 0.0559896 9.94271 -9.11458e-09 10.2656 0C11.4323 0 12.3464 0.322917 13.0078 0.96875C13.6693 1.61458 14 2.51042 14 3.65625C14 4.80729 13.4036 5.97917 12.2109 7.17188L7.34375 11.8594C7.25 11.9531 7.13542 12 7 12Z' fill='%23FF865B'/%3E%3C/svg%3E%0A");
    color: #ff865b;
    padding-left: 24px;
  }
}
