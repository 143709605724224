.landing {
  background: no-repeat 80% 0 / cover #fff url(background.png);
  color: #fff;
  height: 90vh;
  padding-top: 7em !important;
}

.search {
  flex: 1;
}

.search p a {
  color: #4cadcd;
}

.search .landing {
  height: auto;
  padding: 5em 1em 2em !important;
}

.search .search-result {
  padding: 1em;
}

.search .search-result h3,
.search .search-result p,
.search .filter-bar {
  color: #000;
  padding: 0 2%;
}

.search .search-result h3 {
  font-size: 150%;
  font-weight: 300;
}

.product-card {
  padding: 2%;
  flex-grow: 1;
  flex-basis: 16%;
}

.product-card a {
  text-decoration: none;
}

.product-image {
  background-color: #f7f7f9;
  padding: 3em 1em;
  text-align: center;
}

.product-image img {
  height: 10em;
  max-width: 100%;
}

.product-info {
  margin-top: auto;
}

.product-info .designer {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 5px;
  text-transform: uppercase;
  display: block;
  line-height: 1.5em;
  max-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  color: #4e4e51;
}

.product-info strong {
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-weight: 400;
  font-size: 111%;
  margin-bottom: 0.2em;
  max-height: 3em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #262627;
}

.product-info .has-discount-false,
.product-info .has-discount-true {
  margin: 0.5em 0;
}

.product-info .has-discount-false .price {
  color: #4cadcd;
}

.product-info .has-discount-true .price {
  color: DimGray;
  text-decoration: line-through;
}

.product-info .has-discount-false .discount,
.filter-bar {
  display: none;
}

.product-info .has-discount-true .discount {
  color: SandyBrown;
  font-weight: 900;
  padding: 0 0.5em;
}

.search .loading {
  padding: 2%;
}

@media only screen and (min-width: 768px) {
  .search .landing {
    max-height: 424px;
    padding: 9em 7em !important;
  }

  .products {
    display: flex;
    flex-wrap: wrap;
  }

  .product-card {
    flex: 1 24%;
  }

  .product-image {
    padding: 5em 1em;
  }

  .filter-bar {
    align-items: flex-end!important;
    display: flex;
    margin: 3em 0 1.5em;
    gap: 1em;
  }

  .filter-bar div {
    width: 16.66%;
  }

  .filter-bar select,
  .filter-bar button {
    display: block;
    width: 100%;
    margin-top: 1em;
  }

  .filter-bar select {
    background-color: #e4e4e7;
    border: 0;
    border-radius: 0;
    font-size: small;
    height: 3em;
    text-transform: capitalize;
    outline: none;
    padding: .75em;
  }

  .filter-bar button {
    background: black;
    color: #fff;
    border-radius: 0;
    cursor: pointer;
    font-size: small;
    font-weight: bold;
    padding: .8em;
    text-transform: capitalize;
    width: 100%;
    outline: none;
    border: solid 1px Gainsboro;
  }
}
