/**
 * Default application CSS theme.
 *
 * Please put here only colors and background images.
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/background
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/color
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/opacity
 *
 * @see https://google.github.io/styleguide/htmlcssguide.html
 * @see https://github.com/google/closure-stylesheets
 * @see https://en.wikipedia.org/wiki/Web_colors
 */

body {
  background-color: White;
  color: #222;
}

header,
footer {
  background-color: #fff;
  color: #222;
}

footer {
  border-top: solid 1px Gainsboro;
  background: WhiteSmoke;
}

h3 {
  color: DarkBlue;
}

#main > aside {
  background-color: LightCyan;
}

nav ul,
nav a {
  background-color: #fff;
  color: #222;
}

.btn-primary,
.btn-secondary,
.btn-secondary:hover,
nav .menu a.active,
nav .menu a:active,
nav .menu a:hover {
  background-color: #4cadcd;
  border-radius: 4px;
  color: #fafafa;
}

nav .hamburger span,
nav .hamburger span::before,
nav .hamburger span::after {
  background-color: #222;
}

.btn-primary {
  border: solid 1px #4cadcd;
}

.btn-secondary,
.btn-primary:hover {
  background-color: white;
  border: solid 1px #4cadcd;
  color: #4cadcd;
}