/**
 * Main application styles.
 *
 * @see https://google.github.io/styleguide/htmlcssguide.html
 * @see https://github.com/google/closure-stylesheets
 */

body {
  font-family: 'Open Sans', arial, sans-serif;
  -webkit-font-smoothing: antialiased;
}

#main {
  margin: 5em auto 0;
}

header,
footer {
  width: 100%;
}

header {
  position: fixed;
  z-index: 9;
}

header h1 {
  float: left;
  margin-right: 0.75em;
}

header img {
  height: 33px;
}

footer {
  text-align: center;
}

p {
  font-size: 101%;
  line-height: 1.5em;
}

select {
  width: 100%;
  border-radius: 3px;
  font-size: 1em;
  outline: none;
  padding: 0.75em;
}

@media only screen and (min-width: 768px) {
  header,
  footer,
  #main {
    max-width: 1440px;
    width: 100%;
  }

  header,
  footer,
  article {
    margin: 0 auto;
  }

  header {
    left: 50%;
    transform: translateX(-50%);
    padding: 37px 122px !important;
  }

  header h1 {
    margin-top: 0.5em;
  }

  #main {
    margin-top: 8em;
  }
}