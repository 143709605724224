article.AboutPage {
  margin-top: 4.5em;
}

article.AboutPage p {
  margin-top: 1em;
}

@media only screen and (min-width: 768px) {
  article.AboutPage {
    margin-top: 2.5em;
    max-width: 70%;
  }
}
