article.ProfilePage {
  flex: none !important;
  margin-top: 5em;
}

article.ProfilePage h3 {
  font-size: 125%;
}

article.ProfilePage p {
  line-height: 1.25em;
}

article.ProfilePage td {
  padding: 0 0.25em;
}

article.ProfilePage table button {
  padding: 0.25em
}

@media only screen and (min-width: 768px) {
  article.ProfilePage {
    margin-top: 2em;
    width: 50%;
  }
}
